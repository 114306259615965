'use client'

import type { ComponentPropsWithoutRef } from 'react'
import { Icon, Link, Typography } from '@shc/ui'
import { AnalyticsLink } from '@components/analytics'
import useAnalytics, { type SectionContext } from '@hooks/use-analytics'
import IntlToggle from '@components/intl-toggle'
import { useTranslations } from 'next-intl'

type ContactUsProps = Omit<ComponentPropsWithoutRef<'div'>, 'children'> & {
  section: SectionContext['section_name']
}

const ContactUs = ({ section, ...props }: ContactUsProps) => {
  const { trackSnowplow } = useAnalytics()
  const translate = useTranslations('ContactUs')

  const contactUsLabel = translate('contact_us')
  const contactUsLink = '/contact-us'
  const chatLabel = translate('chat_with_us')
  const chatHours = translate('weekday_times')
  const phoneLabel = '1-800-827-4277'
  const phoneHours = translate('weekday_times')
  const phoneLink = 'tel:+18008274277'
  const directoryLabel = translate('phone_directory')
  const directoryLink = '/phone-numbers.cfm'

  const trackClick = () => {
    // track button click
    trackSnowplow({
      event: {
        name: 'contact_us_click',
        data: {
          contact_type: 'chat',
          displayed_hours: chatHours,
        },
      },
      contexts: [
        {
          name: 'component',
          data: { component_text: chatLabel },
        },
        { name: 'section', data: { section_name: section } },
      ],
    })

    // track modal open
    trackSnowplow({
      event: { name: 'modal_open', data: {} },
      contexts: [
        {
          name: 'component',
          data: {
            component_text: chatLabel,
          },
        },
        { name: 'section', data: { section_name: 'footer' } },
        { name: 'modal', data: { modal_name: 'Chat with a care specialist at Sharp' } },
      ],
    })
  }

  return (
    <div {...props}>
      <Typography as="h3" variant="small-body-semibold" className="text-gray-700">
        {translate('need_help')}
      </Typography>
      <ul className="space-y-4 mt-3">
        <li>
          <AnalyticsLink
            role="button"
            href={contactUsLink}
            className="inline-flex flex-row items-center text-sm"
            noUnderline
            snowplow={{
              event: {
                name: 'contact_us_click',
                data: { contact_type: 'contact' },
              },
              contexts: [
                {
                  name: 'component',
                  data: { component_text: contactUsLabel, component_url: contactUsLink },
                },
                { name: 'section', data: { section_name: section } },
              ],
            }}>
            <Icon icon="envelope" className="text-gray-700 pr-1.5" />
            <span>{contactUsLabel}</span>
          </AnalyticsLink>
        </li>
        <li>
          {/* Leave this as a <Link> for now because it currenly triggers two Snowplow events
           we haven't accounted for that yet in <AnalyticsLink> */}
          <Link
            as="button"
            type="button"
            data-chat="true"
            className="inline-flex flex-row items-center text-sm"
            onClick={trackClick}
            noUnderline>
            <Icon icon="comment" className="text-gray-700 pr-1.5" />
            <span>{chatLabel}</span>
          </Link>
          <Typography variant="small-body" className="ml-5 text-gray-700">
            {chatHours}
          </Typography>
        </li>
        <li>
          <AnalyticsLink
            role="button"
            href={phoneLink}
            className="inline-flex flex-row items-center text-sm"
            noUnderline
            snowplow={{
              event: {
                name: 'contact_us_click',
                data: { contact_type: 'call', displayed_hours: phoneHours },
              },
              contexts: [
                {
                  name: 'component',
                  data: { component_text: phoneLabel, component_url: phoneLink },
                },
                { name: 'section', data: { section_name: section } },
              ],
            }}>
            <Icon icon="phone" className="text-gray-700 pr-1.5" />
            <span>{phoneLabel}</span>
          </AnalyticsLink>

          <Typography variant="small-body" className="ml-5 text-gray-700">
            {phoneHours}
            <br />
            {translate('view')}{' '}
            <AnalyticsLink
              href={directoryLink}
              className="text-sm"
              noUnderline
              snowplow={{
                event: {
                  name: 'contact_us_click',
                  data: { contact_type: 'directory', displayed_hours: phoneHours },
                },
                contexts: [
                  {
                    name: 'component',
                    data: { component_text: 'phone directory', component_url: directoryLink },
                  },
                  { name: 'section', data: { section_name: section } },
                ],
              }}>
              {directoryLabel}
            </AnalyticsLink>
          </Typography>
        </li>
        <li>
          <IntlToggle section={section} />
        </li>
      </ul>
    </div>
  )
}

export default ContactUs
