'use client'
import { useState } from 'react'
import { type MouseEvent } from 'react'
import NewsletterSignUp from '@components/newsletter-signup'
import useAnalytics from '@hooks/use-analytics'
import { Button, Typography } from '@shc/ui'
import { useTranslations } from 'next-intl'

function FooterNewsletter() {
  const [isNewsletterModalOpen, setIsNewsletterModalOpen] = useState(false)
  const { trackSnowplow } = useAnalytics()

  const click = (e: MouseEvent<any>) => {
    setIsNewsletterModalOpen(true)
    trackSnowplow({
      event: { name: 'modal_open', data: {} },
      contexts: [
        { name: 'section', data: { section_name: 'footer' } },
        {
          name: 'component',
          data: {
            component_text: e.currentTarget.textContent,
          },
        },
        { name: 'modal', data: { modal_name: 'Newsletter' } },
      ],
    })

    trackSnowplow({
      event: { name: 'component_click', data: {} },
      contexts: [
        { name: 'section', data: { section_name: 'footer' } },
        {
          name: 'component',
          data: {
            component_text: e.currentTarget.textContent,
          },
        },
      ],
    })
  }

  const translate = useTranslations('FooterNewsletter')

  return (
    <>
      <Typography variant="body-semibold" align="center">
        {translate('health_tips')}
      </Typography>
      <Button
        size="sm"
        variant="outlined"
        width="auto"
        className="mt-3 w-[240px] md:w-[200px]"
        onClick={click}>
        {translate('subscribe')}
      </Button>
      <NewsletterSignUp
        isModalOpen={isNewsletterModalOpen}
        setIsModalOpen={setIsNewsletterModalOpen}
      />
    </>
  )
}

export default FooterNewsletter
